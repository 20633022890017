import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from "../_services/cart.service";
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.less']
})
export class CartComponent implements OnInit {

  // @ViewChildren('myitems') subTotalItems: QueryList<ElementRef>;
  @ViewChildren("subTotalWrap") subTotalItems: QueryList<ElementRef>;
  @ViewChildren("subTotalWrap_existing") subTotalItems_existing: QueryList<
    ElementRef
  >;

  constructor(
    public cartService: CartService,
    private currencyPipe: CurrencyPipe,
    private router: Router
  ) { }

  // items = [];
  value;
  items = [];

  //----- calculate total
  get total() {
    console.log('kj: ' + this.items[0]);
    return this.items.reduce(
      (sum, x) => ({
        qtyTotal: this.value,
        price: sum.price + x.qtyTotal * x.price
      }),
      { qtyTotal: this.value, price: 0 }
    ).price;
  }

  changeSubtotal(item, index) {
    const qty = item.qtyTotal;
    const amt = item.price;
    const subTotal = amt * qty;
    const subTotal_converted = this.currencyPipe.transform(subTotal, "USD");

    this.subTotalItems.toArray()[
      index
    ].nativeElement.innerHTML = subTotal_converted;
    this.cartService.saveCart();
  }

  //----- remove specific item
  removeFromCart(item) {
    this.cartService.removeItem(item);
    this.items = this.cartService.getItems();
  }

  //----- clear cart item
  clearCart(items) {
    this.cartService.clearCart(items);
    this.items = [...this.cartService.getItems()];
  }

  ngOnInit(): void {
    this.cartService.loadCart();
    this.items = this.cartService.getItems();
    this.value = this.cartService.getValue();
    console.log('eva: ' + this.value);
  }

  checkout(){
    this.router.navigate(['/checkout']);
  }

}
