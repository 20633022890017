<section class="page-header">
    <div class="overly"></div> 	
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="content text-center">
            <h1 class="mb-3">Checkout</h1>
  
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent justify-content-center">
              <li class="breadcrumb-item"><a routerLink="/cart">Cart</a></li>
              <li class="breadcrumb-item"><a routerLink="/checkout">Billing</a></li>
              <li class="breadcrumb-item active" aria-current="page">Payment</li>
            </ol>
          </nav>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="page-wrapper">
     <div class="checkout shopping">
        <div class="container">
           <div class="row">
              <div class="col-lg-8 pr-5">
               
                <form action="#">
                    <div class="form-check mb-3">
                      
                      <div class="row">
                          <div class="col-12">
                              <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked>
                      <label class="form-check-label" for="exampleRadios1">Please scan the QR code to make the payment</label>
                          </div>
                          <div class="col-12" style="margin-top: 50px; margin-left: 90px;">
                              <img src="https://media.geeksforgeeks.org/wp-content/uploads/20210401125141/mywiki.png" alt="">
                          </div>
                      </div>
                    </div>
                 </form>
  
                 <div class="billing-details mt-5">
                    
                 </div>
              </div>
  
              <!-- Order sidebar Summery -->
              <div class="col-md-6 col-lg-4">
                 <div class="product-checkout-details mt-5 mt-lg-0">
                       <h4 class="mb-4 border-bottom pb-4">Order Summary</h4>
  
                       <div class="media product-card">
                           <p>Kirby Shirt</p>
                          <div class="media-body text-right">
                             <p class="h5">1 x $249</p>
                          </div>
                       </div>
  
                       <ul class="summary-prices list-unstyled mb-4">
                          <li class="d-flex justify-content-between">
                             <span >Subtotal:</span>
                             <span class="h5">$190</span>
                          </li>
                          <li class="d-flex justify-content-between">
                             <span >Shipping:</span>
                             <span class="h5">Free</span>
                          </li>
                          <li class="d-flex justify-content-between">
                             <span>Total</span>
                             <span class="h5">$250</span>
                          </li>
                       </ul>
                 </div>
              </div>
           </div>
        </div>
     </div>
  </div>