import { Component, OnInit } from '@angular/core';
import { AccountService } from '@app/_services';
import { User } from '@app/_models';
import { ModalService } from '../_services/modal.service';
import { CartService } from '../_services/cart.service';

@Component({
  selector: 'app-product',
  templateUrl: 'product.component.html',
  styleUrls: ['product.component.css']
})
export class ProductComponent implements OnInit{
  user: User;
  stars: number[] = [1, 2, 3, 4, 5];
  selectedValue: number;
  value = 1;

    items = [];
    product;


    products = [{
      id: 1, name: 'Small pouch', qtyTotal: 0, price: 5, image: '/assets/pics/bottle3.png'
    },
    {
      id: 2, name: '200 g pack', qtyTotal: 0, price: 200, image: '/assets/pics/bottle.png'
    },
    {
      id: 3, name: '400 g pack', qtyTotal: 0, price: 400, image: '/assets/pics/bottle2.png'
    },
    ];


    ngOnInit() {
      this.product = this.products.filter(x => x.id == 1)[0];
    }

    onChange(deviceValue) {
      console.log('kirti: ' + deviceValue);
      this.product = this.products.filter(x => x.id == deviceValue)[0];
      console.log('sagar: ' + this.product.name);
    } 

  handleMinus() {
    if(this.value > 1){
      this.value--; 
    }
  }
  handlePlus() {
    if(this.value < 10){
      this.value++;
    }
  }
  constructor(private accountService: AccountService, private modalService: ModalService, private cartService: CartService) {
      console.log('ksj product')
      this.user = this.accountService.userValue;
  }

  countStar(star) {
    console.log('ksj' + star);
    this.selectedValue = star;
    console.log('Value of star', star);
  }

  addToCart(id) {
    if (!this.cartService.itemInCart(this.product)) {
          this.product.qtyTotal = this.value;
          this.cartService.addToCart(this.product); //add items in cart
          this.items = [...this.cartService.getItems()];
        }
    this.cartService.updateValue(this.value, this.product);
    this.modalService.open(id);
}

closeModal(id: string) {
    this.modalService.close(id);
}

checkout(){
  this.cartService.checkout();
}

viewcart(){
  this.cartService.viewcart();
}
}