import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// used to create fake backend
import { fakeBackendProvider } from './_helpers';

import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AppComponent } from './app.component';
import { AlertComponent } from './_components';
import { HomeComponent } from './home';;
import { ProductComponent } from './product/product.component'
import { FormsModule } from '@angular/forms';;
import { CartComponent } from './cart/cart.component'
;
import { ModalComponent } from './modal/modal.component'
import { CheckoutComponent } from './checkout/checkout.component';
import { CurrencyPipe } from '@angular/common';;
import { PaymentComponent } from './payment/payment.component'
;
import { FrameComponent } from './frame/frame.component';;
import { HeaderComponent } from './frame/header/header.component'
;
import { FooterComponent } from './frame/footer/footer.component';
@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        AppRoutingModule,
        FormsModule
    ],
    declarations: [
        AppComponent,
        AlertComponent,
        HomeComponent,
        ProductComponent ,
        CartComponent ,
        ModalComponent,
        CheckoutComponent ,
        PaymentComponent ,
        FrameComponent,
        FooterComponent,
        HeaderComponent],

    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

        // provider used to create fake backend
        fakeBackendProvider,
        CurrencyPipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule { };