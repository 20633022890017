<!-- nav -->
<!-- <nav class="navbar navbar-expand navbar-dark bg-dark" *ngIf="user"> -->

  <!-- <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
  />
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script> -->
<!-- main app container -->
<div class="app-container" [ngClass]="{ 'bg-light': user }">
    <!-- <alert></alert> -->
    <nav class="navbar navbar-expand navbar-dark bg-dark">
        <div class="navbar-nav">
            <!-- <a class="nav-item nav-link" routerLink="/home" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a> -->
            <a class="nav-item nav-link" routerLink="/home">Home</a>
            <a class="nav-item nav-link" routerLink="/users" *ngIf="username == 'ksj'" routerLinkActive="active">Users</a>
            <a class="nav-item nav-link" (click)="signin()" *ngIf="user == undefined || user == null" style="margin-left: 1100px">Login</a>
            <a class="nav-item nav-link" (click)="logout()" *ngIf="user" style="margin-left: 1031px">Logout</a>
            <a class="nav-item nav-link" (click)="cart()" style="margin-left: 25px">Cart</a>
        </div>
    </nav>
    <!-- <app-header></app-header> -->
    <router-outlet></router-outlet>
    <app-footer></app-footer>
</div>

<!-- credits -->
<div class="text-center mt-4">

</div>