<footer class="bg-light text-center text-lg-start">
  <!-- Grid container -->
  <div class="container p-4">
    <!--Grid row-->
    <div class="row">
      <!--Grid column-->
      <div class="col-lg-6 col-md-12 mb-4 mb-md-0">
        <h5 class="text-uppercase" style="color: grey; text-align: left; margin-left: 60px;">POLICIES</h5> &nbsp;
        <p style="color: grey; text-align: left; margin-left: 60px;">
          <u> Shipping </u> <br>
          <u> Returns & Cancellation </u><br>
          <u> Terms of Use </u><br>
          <u> Privacy Policy </u>
        </p>
      </div>
      <!--Grid column-->

      <!--Grid column-->
      <div class="col-lg-6 col-md-12 mb-4 mb-md-0">
        <h5 class="text-uppercase" style="color: grey">CONTACT US</h5> &nbsp;
        <p style="color: grey">
          Harsh Kailash Herbals, Adityapur, Jamshedpur - 831005 <br>
          Call Us: <b> 8197164565 </b> <br>
          Mon-Fri: 9:00am - 5:00pm <br>
          Email Us: <b> support@hkherbals.com </b> <br>
          WhatsApp Us: <b> 8197164565 </b>
        </p>
      </div>
      <!--Grid column-->
    </div>
    <!--Grid row-->
  </div>
  <!-- Grid container -->

  <!-- Copyright -->
  <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2);">
    © 2020 Copyright:
    <a class="text-dark" href="https://hkherbals.in/">hkherbals.in</a>
  </div>
  <!-- Copyright -->
</footer>