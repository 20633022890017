import { Component } from '@angular/core';

import { AccountService } from './_services';
import { User } from './_models';
import { CartService } from './_services/cart.service';

@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent {
    user: User;
    username: string
    constructor(private accountService: AccountService, private cartService: CartService) {
        this.accountService.user.subscribe(x => {
            this.user = x,
            this.username = x != undefined ?  x.username: null});
    }

    logout() {
        this.accountService.logout();
    }
    
    signin() {
        this.accountService.signin();
    }

    cart(){
        this.cartService.viewcart();
        console.log('xxx');
    }
}