<section class="page-header">
    <div class="overly"></div> 	
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="content text-center">
            <!-- <h1 class="mb-3">Checkout</h1> -->
  
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent justify-content-center">
              <li class="breadcrumb-item"><a routerLink="/cart">Cart</a></li>
              <li class="breadcrumb-item active" aria-current="page">Billing</li>
              <li class="breadcrumb-item"><a routerLink="/payment">Payment</a></li>
            </ol>
          </nav>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="page-wrapper">
     <div class="checkout shopping">
        <div class="container">
           <div class="row">
              <div class="col-lg-8 pr-5">
               
  
                 <div class="billing-details mt-5">
                    <h4 class="mb-4">Billing Details</h4>
                    <form class="checkout-form">
                       <div class="row">

                        <!-- <div class="col-lg-6">
                            <div class="form-group mb-4">
                              <label for="company_name">Country</label>
                               <select class="form-control">
                                 <option value="January" selected>India</option>
                              </select>
                           </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="form-group mb-4">
                              <label for="first_name">Phone </label>
                              <input type="text" class="form-control" id="phone" placeholder="">
                           </div>
                        </div> -->


                          <div class="col-lg-6">
                              <div class="form-group mb-4">
                                <label for="first_name">First Name</label>
                                <input type="text" class="form-control" id="first_name" placeholder="">
                             </div>
                          </div>
                          <div class="col-lg-6">
                              <div class="form-group mb-4">
                                <label for="last_name">Last Name</label>
                                <input type="text" class="form-control" id="last_name" placeholder="">
                             </div>
                          </div>

                          <div class="col-lg-6">
                            <div class="form-group mb-4">
                              <label for="company_name">Country</label>
                               <select class="form-control">
                                 <option value="January" selected>India</option>
                              </select>
                           </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="form-group mb-4">
                              <label for="first_name">Phone </label>
                              <input type="text" class="form-control" id="phone" placeholder="">
                           </div>
                        </div>

                          <div class="col-lg-12">
                              <div class="form-group mb-4">
                                <label for="first_name">Address</label>
                                <input type="text" class="form-control" id="street" placeholder="">
                             </div>
                          </div>
                           <!-- <div class="col-lg-4">
                              <div class="form-group mb-4">
                                <label for="first_name">City </label>
                                <input type="text" class="form-control" id="city" placeholder="City">
                             </div>
                          </div> -->
                          <div class="col-lg-4">
                            <div class="form-group mb-4">
                              <label for="first_name">State </label>
                              <input type="text" class="form-control" id="state" placeholder="State">
                           </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group mb-4">
                              <label for="first_name">City </label>
                              <input type="text" class="form-control" id="city" placeholder="City">
                           </div>
                        </div>
                           <div class="col-lg-4">
                              <div class="form-group mb-4">
                                <label for="first_name">PIN code</label>
                                <input type="text" class="form-control" id="postcode" placeholder="">
                             </div>
                          </div>
                           <!-- <div class="col-lg-12">
                              <div class="form-group mb-4">
                                <label for="first_name">Phone </label>
                                <input type="text" class="form-control" id="phone" placeholder="">
                             </div>
                          </div> -->
  
                          <div class="col-lg-12">
                           <div class="form-check mb-4">
                              <input type="checkbox" class="form-check-input" id="exampleCheck1">
                              <label class="form-check-label" for="exampleCheck1">Save this information for next time</label>
                            </div>
                          </div>
                           
                       </div>
                    </form>
                 </div>
              </div>
  
              <!-- Order sidebar Summery -->
              <div class="col-md-6 col-lg-4">
                 <div class="product-checkout-details mt-5 mt-lg-0">
                       <h4 class="mb-4 border-bottom pb-4">Order Summary</h4>
  
                       <div class="media product-card">
                           <p>Kirby Shirt</p>
                          <div class="media-body text-right">
                             <p class="h5">1 x $249</p>
                          </div>
                       </div>
  
                       <ul class="summary-prices list-unstyled mb-4">
                          <li class="d-flex justify-content-between">
                             <span >Subtotal:</span>
                             <span class="h5">$190</span>
                          </li>
                          <li class="d-flex justify-content-between">
                             <span >Shipping:</span>
                             <span class="h5">Free</span>
                          </li>
                          <li class="d-flex justify-content-between">
                             <span>Total</span>
                             <span class="h5">$250</span>
                          </li>
                       </ul>
  
                      
                       <!-- <button routerLink="/checkout" class="btn btn-main btn-small">Place Order</button> -->
                       <button (click)="makepayment()" class="btn btn-primary" style="margin-top: 20px">Make Payment</button>
                 </div>
              </div>
           </div>
        </div>
     </div>
  </div>