<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
/>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js"></script>
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
<style>
  .checked {
    color: orange;
  }
</style>
<div class="container body" style="margin-top: 80px">
  <div style="margin-top: 10px" class="card-deck">
        <div class="card bg-light">
          <div class="card-body">
            <img class="product_img"
              src="{{product.image}}"
              alt="gastonica"
            />
          </div>
        </div>
        <div class="card bg-light">
          <div class="card-body">
            <font style="color: #3e8e41;">
              <h5>
                HK Herbals Gastonica | Instant Gas Relief | {{product.name}}
              </h5>  
            </font>
            
            <div class="container bg-light">
              <div class="row">
                <div class="col-5 priceField">
                  <p>Rs {{product.price}}</p>
                </div>
                <div class="col-3">
                 
                      <select type="text" style="width: 7.5rem" class="form-control invalid" id="field_investorTypeId" name="product" (change)="onChange($event.target.value)" placeholder="product">
                          <option *ngFor="let product of products"  [value]="product.id">{{product.name}}</option>
                          </select>
                </div>
              </div>
            </div>
            <div class="row" style="margin: 0rem">
              <div>
                <ul
                  class="list-inline rating-list"
                  *ngFor="let star of stars"
                  style="display: inline-block"
                >
                  <li
                    (click)="countStar(star)"
                    [ngClass]="{ selected: star <= selectedValue }"
                  >
                    <i class="fa fa-star"></i>
                  </li>
                </ul>
              </div>
            </div>
            <!-- ends -->
            <p>Quantity</p>
            <div class="wrapper">
              <button class="plusminus" (click)="handleMinus()">-</button>
              <input 
                type="integer"
                class="num"
                value="0"
                [(ngModel)]="value"
              />
              <button class="plusminus" (click)="handlePlus()">+</button>
            </div>
            <div class="container">
              <div class="row">

                  <!-- <div> -->
                      <button (click)="addToCart('custom-modal-2')" class="button button1 button11">Add to cart</button>
                  <!-- </div> -->

                <!-- &nbsp;&nbsp;&nbsp; -->
                <!-- <div> -->
                  <!-- <button (click)="checkout();" class="button button2 button22">Buy now</button> -->
                <!-- </div> -->
                <button (click)="checkout();" class="button button2 button22">Buy now</button>

              <jw-modal id="custom-modal-2">
                  <div>
                  <button (click)="viewcart();" class="button button2 button11" style="margin-top: 20px">View my cart</button>
                  <button (click)="checkout();" class="button button2 button22" style="margin-top: 20px">Check out</button>
                  <a style="color: #3e8e41" (click)="closeModal('custom-modal-2');">Continue shopping</a>
              </div>
              </jw-modal>

              </div>
            </div>
          </div>
        </div>
  </div>

</div>