<!DOCTYPE html>
<html lang="en">
  <head>
    <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
  />
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
  </head>
  <body class="bg-light">

    <div class="container bg-light" style="margin-top: 20px">
      <div
        id="myCarousel"
        class="carousel slide"
        data-ride="carousel"
        style="margin-top: 20px"
      >
        <!-- Indicators -->
        <ol class="carousel-indicators">
          <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
          <li data-target="#myCarousel" data-slide-to="1"></li>
          <li data-target="#myCarousel" data-slide-to="2"></li>
        </ol>

        <!-- Wrapper for slides -->
        <div class="carousel-inner">
          <div class="item active">
            <img
              src="/assets/pics/heroPhoto.png"
              alt="Los Angeles"
              style="width: 100%"
            />
          </div>
        </div>

        <!-- Left and right controls -->
        <a class="left carousel-control" href="#myCarousel" data-slide="prev">
          <span class="glyphicon glyphicon-chevron-left"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="right carousel-control" href="#myCarousel" data-slide="next">
          <span class="glyphicon glyphicon-chevron-right"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>

    <div class="container bg-light">
      <div style="margin-top: 10px" class="card-deck">
        <div class="card bg-light">
          <a href="/product"
            ><img
              class="card-img-top"
              src="/assets/pics/bottle.png"
              alt="gastonica"
              style="margin: 0px"
              height="350px"
          /></a>
          <!--
          <div class="card-body">
            <small class="text-muted">
              <div class="container">
                <div class="row">
                  <div class="col-8"><h4 class="card-title">Gastonica</h4></div>
                  
                  <div class="row">
                    <div>
                      <ul
                        class="list-inline rating-list"
                        *ngFor="let star of stars"
                        style="display: inline-block"
                      >
                        <li
                          (click)="countStar(star)"
                          [ngClass]="{ selected: star <= selectedValue }"
                        >
                          <i class="fa fa-star"></i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </small>
          </div>

        -->
        </div>
        <div class="card bg-light">
            <a href="/product"
              ><img
                class="card-img-top"
                src="/assets/pics/bottle.png"
                alt="gastonica"
                style="margin: 0px"
                height="350px"
            /></a>
          <!--
            <div class="card-body">
              <small class="text-muted">
                <div class="container">
                  <div class="row">
                    <div class="col-8"><h4 class="card-title">Aasma 21</h4></div>
                    
                    <div class="row">
                      <div>
                        <ul
                          class="list-inline rating-list"
                          *ngFor="let star of stars"
                          style="display: inline-block"
                        >
                          <li
                            (click)="countStar(star)"
                            [ngClass]="{ selected: star <= selectedValue }"
                          >
                            <i class="fa fa-star"></i>
                          </li>
                        </ul>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </small>
            </div>
          -->
          </div>
      </div>
    </div>
  </body>
</html>
