<section class="page-header">
    <div class="overly"></div> 	
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="content text-center">
            <!-- <h1 class="mb-3">Checkout</h1> -->
  
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb bg-transparent justify-content-center">
                <li class="breadcrumb-item active" aria-current="page">Cart</li>
                <li class="breadcrumb-item"><a routerLink="/checkout">Billing</a></li>
              <li class="breadcrumb-item"><a routerLink="/payment">Payment</a></li>
            </ol>
          </nav>
          </div>
        </div>
      </div>
    </div>
  </section>

<div class="row" style="margin-top: 20px">
  <div class="card border-0">
    <div class="card-body">
     

      <div class="col-12">
        <div class="container">
          <div class="row">
            
            <!-- <div class="col-6">
                <h3>Your cart</h3>
              </div> -->

            <div class="col-6">
              <a href="/product">Go Back</a>
            </div>
          </div>
        </div>
      </div>


      <hr />
      <div class="col-12" *ngFor="let item of items; let i = index">
        <div class="row" id="{{ item.id }}">
          <div class="col-6" style="margin-top: 30px; left: 30px;">
            <div class=" ">
              {{ item.name }}
              <small>( {{ item.price | currency }} ) </small>
            </div>
          </div>
          <div class="col-2 p-0">
            <input
              [(ngModel)]="item.qtyTotal"
              [ngModelOptions]="{ standalone: true }"
              min="1"
              type="number"
              value="{{ item.qtyTotal }}"
              name=""
              class="form-control"
              (change)="changeSubtotal(item, i)"
            />
          </div>
          <div class="col-2 pr-0">
            <div #subTotalWrap>{{ item.price * item.qtyTotal | currency }}</div>
          </div>
          <div class="col-1 pl-0">
            <button class="btn btn-secondary" (click)="removeFromCart(item)">
              x
            </button>
          </div>
        </div>
      </div>

      <div class="col-12">
        <hr />
        <div class="container">
          <div class="row">
            <div class="col-6">
                <a style="color: red" (click)="clearCart(items)">
                  Clear Items
                </a>
              </div>
            <div class="col-6" style="margin-left: 700px">
              Sub Total:
              <span class="font-weight-bold">{{ total | currency }}</span>
              
            </div>
            <!-- <div class="col-2">
                <button class="btn btn-primary" (click)="clearCart(items)">
                  Clear Items
                </button>
              </div> -->
          </div>
        </div>
      </div>

      <div class="col-12" style="margin-top: 20px; margin-left: 780px">
        <button class="btn btn-primary" (click)="checkout()">Checkout</button>
      </div>
    </div>
  </div>
</div>
