import { Component } from '@angular/core';

import { User } from '@app/_models';
import { AccountService } from '@app/_services';

@Component({ 
    selector: 'app-home',
    templateUrl: 'home.component.html',
    styleUrls:['home.component.css']
})
export class HomeComponent {
    user: User;
    stars: number[] = [1, 2, 3, 4, 5];
    selectedValue: number;

    constructor(private accountService: AccountService) {
        console.log('ksj home')
    }
    countStar(star) {
        console.log('ksj' + star);
        this.selectedValue = star;
        console.log('Value of star', star);
      }
}